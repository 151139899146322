import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'

import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'
import ImgNossoAtendimento from '../images/nosso-atendimento.svg'
import ImgNossosCertificados from '../images/nossos-certificados.svg'
import Check from '../images/tick.svg'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledWhyHostdime,
  content,
  styledBox
} from '../components/styles/WhyHostdime.styles'
import css from '@emotion/css'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledWhyHostdime}>
        <Container>
          <Row
            flexDirection={['column']}
            css={content}
          >
            <Column
              width={[1]}
            >
              <H4>Por que a HostDime?</H4>
              <Paragraph>A HostDime oferece infraestrutura certificada com tecnologia de ponta para a operação dos sistemas críticos que são fundamentais para o funcionamento de uma organização.</Paragraph>
              <Paragraph>Garantimos soluções eficientes, atendimento personalizado e uma equipe de profissionais experientes para que nossos clientes tenham todo o apoio necessário para se tornarem mais competitivos no mercado. </Paragraph>
            </Column>
            <Column
              width={[1]}
              mt={34}
            >
              <H6><img src={Check} /> Empresa global com presença em mais de 8 países</H6>
              <H6><img src={Check} /> Baixa latência</H6>
              <H6><img src={Check} /> Atendimento diferenciado com gerentes de contas</H6>
              <H6><img src={Check} /> Mais de 15 anos de experiência.</H6>
              <H6><img src={Check} /> Soluções de tecnologia de ponta em configurações ágeis, otimizadas e flexíveis para um melhor desempenho.</H6>
              <H6><img src={Check} /> Flexibilidade de pagamento em um serviço cobrado em reais, sem influência da flutuação cambial ou impostos de importação.</H6>
            </Column>
          </Row>
        </Container>
      </section>
      <section>
        <Container
          css={css(
            mq({
              padding: [0, 0, 'initial'],
              margin: [0, 0, 'initial'],
              maxWidth: ['100%', '100%', 'initial']
            })
          )}
        >
          <Row
            flexDirection={['column', 'column', 'row']}
            mx={null}
          >
            <Column
              pt={[50]}
              pb={[50]}
              pl={[35, 90, 68, 110]}
              pr={[35, 90, 68, 110]}
              px={null}
              width={[1, 1, 1/2, 1/2]}
              style={{
                backgroundColor: '#f9fafa'
              }}
              css={styledBox}
            >
              <img
                src={ImgNossoAtendimento} 
                style={{
                  width: 110,
                  height: 110
                }}
              />
              <H5>Nosso Atendimento</H5>
              <Paragraph
                width={[267, 422, 414, 414]}
              >
                Atendimento multiplataforma ágil, eficiente e personalizado. Disponível sempre que você precisar.
              </Paragraph><br />
              <Button
                onClick={() => navigate('/nosso-atendimento')}
                outlined
                schema='lightPeriwinkle'
                align='center'
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontWeight: 600,
                  letterSpacing: 'normal',
                  lineHeight: 1.43,
                  padding: '12px 16px 12px',
                  width: 150,
                  outline: 'none',
                  border: 'none',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                  backgroundColor: '#ffffff',
                  marginTop: 30
                }}
              >
                SAIBA MAIS
              </Button>
            </Column>
            <Column
              pt={[50]}
              pb={[50]}
              pl={[35, 90, 68, 110]}
              pr={[35, 90, 68, 110]}
              px={null}
              width={[1, 1, 1/2, 1/2]}
              style={{
                backgroundColor: '#f3f5f5'
              }}
              css={styledBox}
            >
              <img
                src={ImgNossosCertificados} 
                style={{
                  width: 110,
                  height: 110
                }}
              />
              <H5>Premiações e Certificados</H5>
              <Paragraph
                width={[267, 422, 422, 422]}
              >
                Conheça nossas premiações e os nossos certificados de abrangência internacional que asseguram a nossa alta capacidade de atendimento e confiabilidade de infraestrutura local.
              </Paragraph>
              <Button
                onClick={() => navigate('/premiacoes-e-certificados')}
                outlined
                schema='lightPeriwinkle'
                align='center'
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontWeight: 600,
                  letterSpacing: 'normal',
                  lineHeight: 1.43,
                  padding: '12px 16px 12px',
                  width: 150,
                  outline: 'none',
                  border: 'none',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                  backgroundColor: '#ffffff',
                  marginTop: 30
                }}
              >
                VEJA AQUI
              </Button>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Por que a Hostdime?' />
      <PageTemplate
        title='Por que a Hostdime?'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
